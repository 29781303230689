@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#vote-for-poem-container {
  width: 70%;
  margin: 50px auto;
  text-align: center; }
  @media screen and (max-width: 1150px) {
    #vote-for-poem-container {
      width: 80%; } }
  @media screen and (max-width: 680px) {
    #vote-for-poem-container {
      width: 90%; } }
  #vote-for-poem-container p {
    width: 80%;
    font-size: 20px;
    color: #004581;
    margin: 40px auto; }
    @media screen and (max-width: 1150px) {
      #vote-for-poem-container p {
        width: 80%; } }
    @media screen and (max-width: 680px) {
      #vote-for-poem-container p {
        width: 90%; } }
  #vote-for-poem-container hr {
    border: #004581 0.5px solid; }
