@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#lyrics-container {
  text-align: center;
  width: 30%;
  margin: 50px auto; }
  @media screen and (max-width: 680px) {
    #lyrics-container {
      width: 100%; } }
  #lyrics-container #text-pre {
    font-family: "Comfortaa";
    line-height: 24px;
    white-space: pre-wrap; }
