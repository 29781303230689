@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-stats-container #visits-chart {
  width: 70vw;
  height: 70vh;
  margin: auto; }

#manager-stats-container table {
  width: 90%;
  margin: 50px auto 0;
  text-align: center; }

#manager-stats-container tr {
  display: inline-block;
  cursor: default;
  border: 1px solid #004581;
  width: 35%;
  margin: 10px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  color: #004581;
  border-radius: 10px; }
  @media screen and (max-width: 1150px) {
    #manager-stats-container tr {
      width: 60%;
      margin: 5px; } }
  @media screen and (max-width: 680px) {
    #manager-stats-container tr {
      width: 90%; } }

#manager-stats-container td {
  width: 1%;
  margin: auto; }
