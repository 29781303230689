@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#poem-title-container {
  margin: 40px;
  text-align: center; }

#author-poem-title-container {
  line-height: 25px; }
  #author-poem-title-container a {
    color: #004581;
    font-size: 25px; }
  #author-poem-title-container * {
    display: inline;
    vertical-align: middle; }
  #author-poem-title-container img {
    height: 20px;
    margin: 5px;
    cursor: pointer; }
