@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#poem-search-bar {
  text-align: center;
  text-align-last: center;
  margin: 70px auto; }
  #poem-search-bar * {
    z-index: 1;
    vertical-align: middle;
    display: inline-block; }
  @media screen and (max-width: 680px) {
    #poem-search-bar * {
      width: 100%;
      margin: auto;
      display: block; } }

#search-options {
  height: 36px;
  color: white;
  background: linear-gradient(90deg, #004581, #048ABD);
  appearance: none;
  font-family: "Comfortaa";
  width: 130px;
  border-radius: 15px 0 0 15px;
  font-size: 14px;
  text-align: center;
  text-align-last: center;
  padding: 0 10px;
  border-width: 0;
  cursor: pointer;
  overflow: none; }
  #search-options:focus {
    outline-width: 0; }
  @media screen and (max-width: 680px) {
    #search-options {
      width: 255px;
      height: 40px;
      border-radius: 15px 15px 0 0; } }

#search-wrapper {
  height: 30px;
  width: 300px;
  position: relative;
  line-height: 30px;
  border: 1px solid #004581;
  border-radius: 15px 0 0 15px; }
  @media screen and (max-width: 680px) {
    #search-wrapper {
      width: 250px;
      height: 40px;
      border-radius: 0; } }
  #search-wrapper input {
    border: none;
    border-radius: 15px 0 0 15px;
    padding: 15px;
    height: 100%;
    display: block;
    width: 100%;
    box-sizing: border-box;
    transition: all .5s;
    font-size: 16px;
    font-family: "Comfortaa";
    color: #048ABD; }
    @media screen and (max-width: 680px) {
      #search-wrapper input {
        border-radius: 0; } }
  #search-wrapper #search-options-menu {
    width: 100%;
    margin: 10px auto;
    background: white;
    border: 1px solid #004581;
    border-radius: 15px;
    box-shadow: 0 0 2px 0 #011B48; }
    #search-wrapper #search-options-menu.hidden {
      display: none; }
    #search-wrapper #search-options-menu span {
      height: 40px;
      width: 100%;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      color: #004581; }
      #search-wrapper #search-options-menu span:hover {
        text-decoration: underline; }
  #search-wrapper img {
    position: absolute;
    width: 25px;
    top: 0;
    right: 5px;
    cursor: pointer; }
    @media screen and (max-width: 680px) {
      #search-wrapper img {
        width: 35px; } }

#search-button {
  height: 32px;
  width: 45px;
  line-height: 32px;
  cursor: pointer;
  background: linear-gradient(90deg, #004581, #048ABD);
  font-family: "Comfortaa";
  border-width: 0;
  border-radius: 0 15px 15px 0;
  font-size: 14px; }
  @media screen and (max-width: 680px) {
    #search-button {
      width: 252px;
      height: 40px;
      border-radius: 0 0 15px 15px;
      line-height: 40px; } }
  #search-button img {
    width: 25px;
    display: inline-block; }
