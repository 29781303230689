@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#loader-container {
  width: 50px;
  margin: auto;
  text-align: center; }

#ml-loader {
  width: 50px;
  height: 50px;
  z-index: -1; }

#ml-loader div {
  -webkit-animation: 1.2s opaque ease-in-out infinite both;
  animation: 1.2s opaque ease-in-out infinite both; }

#ml-loader div::after {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 18px;
  border-radius: 10px;
  background-color: #048ABD; }

#ml-loader div:nth-child(1) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0); }

#ml-loader div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }

#ml-loader div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

#ml-loader div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

#ml-loader div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

#ml-loader div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s; }

#ml-loader div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

#ml-loader div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s; }

#ml-loader div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

#ml-loader div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s; }

#ml-loader div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

#ml-loader div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s; }

#ml-loader div:nth-child(13) {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

@-webkit-keyframes opaque {
  0%, 40%, 100% {
    opacity: 0.1; }
  40% {
    opacity: 1; } }

@keyframes opaque {
  0%, 40%, 100% {
    opacity: 0.1; }
  40% {
    opacity: 1; } }
