@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#home-page-container {
  text-align: center; }

#home-first-page-container {
  font-size: 50px;
  min-height: 550px;
  height: 80vh;
  padding-top: 20vh;
  color: #004581; }
  #home-first-page-container #arrow {
    position: absolute;
    bottom: 0;
    margin: 10px auto;
    right: 0;
    left: 0;
    animation: infinite go-down 1s; }
  #home-first-page-container #home-search-bar {
    border: 1px solid #004581;
    border-radius: 15px;
    border-width: 1;
    width: 450px;
    height: 30px;
    font-size: 18px;
    color: #011B48;
    font-family: "Comfortaa";
    transition: all .5s; }
    #home-first-page-container #home-search-bar:focus {
      width: 320px;
      outline-width: 0; }
    @media screen and (max-width: 680px) {
      #home-first-page-container #home-search-bar {
        width: 300px; }
        #home-first-page-container #home-search-bar:focus {
          width: 350px; } }
  #home-first-page-container span {
    padding: 15px; }
  #home-first-page-container * {
    display: block;
    text-align: center;
    margin: auto; }

@keyframes go-down {
  0% {
    margin: 40px auto; }
  50% {
    margin: 50px auto; }
  100% {
    margin: 40px auto; } }

#home-second-page-container {
  height: 75vh;
  min-height: 700px;
  padding: 12.5vh 5%; }
  @media screen and (max-width: 1150px) {
    #home-second-page-container {
      height: 90vh;
      padding: 5vh 5%; } }
  #home-second-page-container div {
    font-size: 20px;
    color: #004581;
    vertical-align: middle;
    display: inline-block; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container div {
        display: block;
        font-size: 16px;
        vertical-align: none; } }
  #home-second-page-container .pl-title {
    font-size: 30px; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container .pl-title {
        font-size: 26px; } }
  #home-second-page-container #home-about {
    width: 40%;
    line-height: 30px;
    padding: 0 40px; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container #home-about {
        width: 100%;
        padding: 0; } }
  #home-second-page-container #home-get-it-on-market {
    width: 15%; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container #home-get-it-on-market {
        width: 100%; } }
    #home-second-page-container #home-get-it-on-market img {
      display: block;
      margin: 30px 0;
      width: 100%;
      max-width: 200px; }
      @media screen and (max-width: 1150px) {
        #home-second-page-container #home-get-it-on-market img {
          display: inline-block;
          margin: 30px;
          width: 30%; } }
      @media screen and (max-width: 680px) {
        #home-second-page-container #home-get-it-on-market img {
          display: block;
          width: 100%;
          margin: 20px auto; } }
  #home-second-page-container #home-plios {
    width: 25%; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container #home-plios {
        width: 100%; } }
    #home-second-page-container #home-plios img {
      width: 80%; }
      @media screen and (max-width: 1150px) {
        #home-second-page-container #home-plios img {
          height: 50vh;
          width: auto; } }

#home-third-page-container {
  height: 50vh;
  min-height: 550px;
  padding: 25vh 0; }
  #home-third-page-container div {
    padding: 0 50px;
    vertical-align: middle;
    display: inline-block; }
    @media screen and (max-width: 1150px) {
      #home-third-page-container div {
        padding: 0;
        width: 100%; } }
  #home-third-page-container #home-contact {
    font-size: 20px; }
    #home-third-page-container #home-contact div {
      display: block; }
    #home-third-page-container #home-contact a {
      color: #004581; }
    #home-third-page-container #home-contact img {
      display: inline-block;
      height: 35px;
      padding: 10px;
      filter: brightness(0);
      transition: all 0.25s; }
      #home-third-page-container #home-contact img:hover {
        filter: brightness(1); }
