@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#player-buttons-container {
  text-align: center; }
  #player-buttons-container div {
    margin: 10px;
    display: inline-block; }
    @media screen and (max-width: 680px) {
      #player-buttons-container div {
        margin: 0 5px 20px; } }
