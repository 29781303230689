@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-feedback-container p {
  text-align: center;
  margin: 50px auto;
  font-size: 26px; }

#manager-feedback-container td {
  padding: 15px 0; }
