@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#author-container {
  width: 70%;
  margin: auto;
  line-height: 24px; }
  #author-container #author-info #author-img-div {
    width: 20%; }
    @media screen and (max-width: 1150px) {
      #author-container #author-info #author-img-div {
        width: 40%;
        margin: 0 30%; } }
    @media screen and (max-width: 680px) {
      #author-container #author-info #author-img-div {
        width: 50%;
        margin: 0 25%; } }
    #author-container #author-info #author-img-div img {
      width: 100%;
      border-radius: 50%; }
  #author-container #author-info #author-header-div {
    width: 70%; }
    @media screen and (max-width: 1150px) {
      #author-container #author-info #author-header-div {
        width: 100%; } }
  #author-container #author-info div {
    vertical-align: top;
    margin: 20px 2%;
    display: inline-block; }
  #author-container #author-poems {
    text-align: center; }
    #author-container #author-poems a {
      text-decoration: none;
      margin: 10px;
      display: inline-block; }
      @media screen and (max-width: 680px) {
        #author-container #author-poems a {
          margin: 10px 0; } }
  #author-container #author-bio {
    font-family: "Comfortaa";
    white-space: pre-wrap; }
