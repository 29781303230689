@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#not-found-container {
  text-align: center;
  margin: 50px; }
  #not-found-container span {
    font-size: 72px; }
  #not-found-container p {
    font-size: 36px; }
