@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#profile-container {
  text-align: center;
  padding: 50px; }
  #profile-container p {
    font-size: 24px; }
  #profile-container a {
    text-decoration: none; }

#profile-liked-container {
  padding: 30px 0; }

#profile-liked-poems-container {
  width: 100%; }
  #profile-liked-poems-container a {
    margin: 10px;
    display: inline-block; }
    @media screen and (max-width: 680px) {
      #profile-liked-poems-container a {
        margin: 10px 0; } }
