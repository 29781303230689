@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-actions-container table {
  width: 90%;
  margin: 50px auto 0;
  text-align: center; }

#manager-actions-container tr {
  display: inline-block;
  margin: 10px; }
