@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Yellowtail);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#hamburger-button {
  vertical-align: middle;
  width: 40px;
  height: 30px;
  margin-right: 20px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  cursor: pointer;
  display: inline-block; }

#hamburger-button span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #004581;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: .25s ease-in-out; }

#hamburger-button span:nth-child(1) {
  top: 0px; }

#hamburger-button span:nth-child(2), #hamburger-button span:nth-child(3) {
  top: 9px; }

#hamburger-button span:nth-child(4) {
  top: 18px; }

#hamburger-button.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%; }

#hamburger-button.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

#hamburger-button.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

#hamburger-button.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#auth-nav-block-container span {
  color: white;
  cursor: pointer; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#mobile-header-menu-container .mobile-header-search {
  display: block;
  text-align: center; }
  #mobile-header-menu-container .mobile-header-search:focus {
    width: 200px; }

#mobile-header-menu-container .mobile-header-menu {
  border-radius: 40px;
  display: block;
  height: 0;
  right: 0;
  position: absolute;
  background: #004581;
  transition: all .4s;
  top: 70px;
  z-index: 1000; }
  #mobile-header-menu-container .mobile-header-menu a, #mobile-header-menu-container .mobile-header-menu span {
    color: white;
    display: block;
    height: 0;
    font-size: 0;
    transition: .3s;
    text-align: center; }
  #mobile-header-menu-container .mobile-header-menu input {
    margin: auto;
    height: 0;
    transition: .3s; }
  @media screen and (min-width: 1150px) {
    #mobile-header-menu-container .mobile-header-menu {
      margin-right: 20px;
      width: 300px; }
      #mobile-header-menu-container .mobile-header-menu a, #mobile-header-menu-container .mobile-header-menu span {
        width: 238px; } }
  @media screen and (min-width: 680px) and (max-width: 1150px) {
    #mobile-header-menu-container .mobile-header-menu {
      margin-right: 20px;
      width: 300px; }
      #mobile-header-menu-container .mobile-header-menu a, #mobile-header-menu-container .mobile-header-menu span {
        width: 238px; } }
  @media screen and (max-width: 680px) {
    #mobile-header-menu-container .mobile-header-menu {
      margin: 0 10%;
      width: 80%; }
      #mobile-header-menu-container .mobile-header-menu #auth-nav-block-container, #mobile-header-menu-container .mobile-header-menu #site-nav-block-container {
        width: 100%;
        text-align: center; } }

#mobile-header-menu-container .mobile-opened-header-menu {
  height: 340px; }
  #mobile-header-menu-container .mobile-opened-header-menu a, #mobile-header-menu-container .mobile-opened-header-menu span {
    height: 70px;
    font-size: 16px;
    transition: .5s; }
  #mobile-header-menu-container .mobile-opened-header-menu input {
    height: 30px;
    transition: .5s; }

#mobile-header-menu-container .mobile-opened-header-menu.is-admin {
  height: 430px; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

header {
  padding-left: 3%;
  height: 70px;
  line-height: 70px; }
  header div {
    display: inline-block; }
  header a {
    color: white;
    cursor: pointer;
    transition: all .2s ease-in-out; }

.nav-container {
  float: right; }

.header-title {
  font-size: 40px;
  text-decoration: none;
  color: #004581; }

.nav-search {
  border-left: 1px dashed #97CBDC;
  border-right: 1px dashed #97CBDC;
  border-radius: 15px;
  border-width: 0;
  width: 150px;
  height: 30px;
  padding: 0 20px;
  font-size: 18px;
  color: #011B48;
  font-family: "Comfortaa";
  margin: 0 20px;
  transition: all .5s; }
  .nav-search:focus {
    width: 220px;
    outline-width: 0; }

@media screen and (min-width: 680px) and (max-width: 1150px) {
  .header-search {
    border: 0; } }

@media screen and (max-width: 680px) {
  .header-search {
    display: none; } }

.nav-item {
  padding: 0 30px;
  text-decoration: none; }
  @media screen and (max-width: 1150px) {
    .nav-item {
      display: none; } }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

footer {
  margin: 2%;
  text-align: center;
  font-size: 14px; }
  footer .pl-title {
    font-size: 20px; }
  footer a, footer span {
    color: #011B48;
    line-height: 20px; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#info-alert-container {
  width: 60%;
  padding: 20px;
  margin: 20px auto;
  border-radius: 10px;
  text-align: center;
  transition: all 1s; }

.error-style {
  border: 1px #b7241b solid;
  background-color: #fcd1d2;
  color: #b7241b; }

.success-style {
  border: 1px #157711 solid;
  background-color: #d9efce;
  color: #157711; }

.info-style {
  border: 1px #004581 solid;
  background-color: #DDE8F0;
  color: #004581; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#form-view-container {
  text-align: center;
  margin: 50px auto; }
  #form-view-container textarea, #form-view-container input {
    display: block;
    font-family: "Comfortaa";
    margin: 10px auto;
    color: #011B48;
    text-align: center; }
  #form-view-container .form-free-text {
    font-size: 18px;
    margin: 10px auto; }
  #form-view-container .form-input {
    width: 450px;
    font-size: 16px;
    border-radius: 5px;
    border: 0.5px solid #004581;
    background-color: white; }
    @media screen and (max-width: 680px) {
      #form-view-container .form-input {
        width: 80%; } }
  #form-view-container .form-textfield {
    height: 40px; }
  #form-view-container textarea {
    height: 200px;
    resize: none; }
  #form-view-container .form-button {
    color: white;
    width: 350px;
    border: 0;
    margin: 30px auto; }
    @media screen and (max-width: 680px) {
      #form-view-container .form-button {
        width: 85%; } }
  #form-view-container .file-input {
    background-color: #004581;
    cursor: pointer;
    width: 450px;
    height: 45px;
    line-height: 45px;
    vertical-align: middle;
    color: white;
    text-align: center;
    margin: auto;
    border-radius: 5px;
    transition: all .1s; }
    @media screen and (max-width: 680px) {
      #form-view-container .file-input {
        width: 85%; } }
    #form-view-container .file-input:hover {
      background-color: #048ABD; }
    #form-view-container .file-input label {
      cursor: pointer; }
    #form-view-container .file-input input {
      visibility: hidden; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#home-page-container {
  text-align: center; }

#home-first-page-container {
  font-size: 50px;
  min-height: 550px;
  height: 80vh;
  padding-top: 20vh;
  color: #004581; }
  #home-first-page-container #arrow {
    position: absolute;
    bottom: 0;
    margin: 10px auto;
    right: 0;
    left: 0;
    -webkit-animation: infinite go-down 1s;
            animation: infinite go-down 1s; }
  #home-first-page-container #home-search-bar {
    border: 1px solid #004581;
    border-radius: 15px;
    border-width: 1;
    width: 450px;
    height: 30px;
    font-size: 18px;
    color: #011B48;
    font-family: "Comfortaa";
    transition: all .5s; }
    #home-first-page-container #home-search-bar:focus {
      width: 320px;
      outline-width: 0; }
    @media screen and (max-width: 680px) {
      #home-first-page-container #home-search-bar {
        width: 300px; }
        #home-first-page-container #home-search-bar:focus {
          width: 350px; } }
  #home-first-page-container span {
    padding: 15px; }
  #home-first-page-container * {
    display: block;
    text-align: center;
    margin: auto; }

@-webkit-keyframes go-down {
  0% {
    margin: 40px auto; }
  50% {
    margin: 50px auto; }
  100% {
    margin: 40px auto; } }

@keyframes go-down {
  0% {
    margin: 40px auto; }
  50% {
    margin: 50px auto; }
  100% {
    margin: 40px auto; } }

#home-second-page-container {
  height: 75vh;
  min-height: 700px;
  padding: 12.5vh 5%; }
  @media screen and (max-width: 1150px) {
    #home-second-page-container {
      height: 90vh;
      padding: 5vh 5%; } }
  #home-second-page-container div {
    font-size: 20px;
    color: #004581;
    vertical-align: middle;
    display: inline-block; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container div {
        display: block;
        font-size: 16px;
        vertical-align: none; } }
  #home-second-page-container .pl-title {
    font-size: 30px; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container .pl-title {
        font-size: 26px; } }
  #home-second-page-container #home-about {
    width: 40%;
    line-height: 30px;
    padding: 0 40px; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container #home-about {
        width: 100%;
        padding: 0; } }
  #home-second-page-container #home-get-it-on-market {
    width: 15%; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container #home-get-it-on-market {
        width: 100%; } }
    #home-second-page-container #home-get-it-on-market img {
      display: block;
      margin: 30px 0;
      width: 100%;
      max-width: 200px; }
      @media screen and (max-width: 1150px) {
        #home-second-page-container #home-get-it-on-market img {
          display: inline-block;
          margin: 30px;
          width: 30%; } }
      @media screen and (max-width: 680px) {
        #home-second-page-container #home-get-it-on-market img {
          display: block;
          width: 100%;
          margin: 20px auto; } }
  #home-second-page-container #home-plios {
    width: 25%; }
    @media screen and (max-width: 1150px) {
      #home-second-page-container #home-plios {
        width: 100%; } }
    #home-second-page-container #home-plios img {
      width: 80%; }
      @media screen and (max-width: 1150px) {
        #home-second-page-container #home-plios img {
          height: 50vh;
          width: auto; } }

#home-third-page-container {
  height: 50vh;
  min-height: 550px;
  padding: 25vh 0; }
  #home-third-page-container div {
    padding: 0 50px;
    vertical-align: middle;
    display: inline-block; }
    @media screen and (max-width: 1150px) {
      #home-third-page-container div {
        padding: 0;
        width: 100%; } }
  #home-third-page-container #home-contact {
    font-size: 20px; }
    #home-third-page-container #home-contact div {
      display: block; }
    #home-third-page-container #home-contact a {
      color: #004581; }
    #home-third-page-container #home-contact img {
      display: inline-block;
      height: 35px;
      padding: 10px;
      -webkit-filter: brightness(0);
              filter: brightness(0);
      transition: all 0.25s; }
      #home-third-page-container #home-contact img:hover {
        -webkit-filter: brightness(1);
                filter: brightness(1); }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#poem-search-bar {
  text-align: center;
  text-align-last: center;
  margin: 70px auto; }
  #poem-search-bar * {
    z-index: 1;
    vertical-align: middle;
    display: inline-block; }
  @media screen and (max-width: 680px) {
    #poem-search-bar * {
      width: 100%;
      margin: auto;
      display: block; } }

#search-options {
  height: 36px;
  color: white;
  background: linear-gradient(90deg, #004581, #048ABD);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: "Comfortaa";
  width: 130px;
  border-radius: 15px 0 0 15px;
  font-size: 14px;
  text-align: center;
  text-align-last: center;
  padding: 0 10px;
  border-width: 0;
  cursor: pointer;
  overflow: none; }
  #search-options:focus {
    outline-width: 0; }
  @media screen and (max-width: 680px) {
    #search-options {
      width: 255px;
      height: 40px;
      border-radius: 15px 15px 0 0; } }

#search-wrapper {
  height: 30px;
  width: 300px;
  position: relative;
  line-height: 30px;
  border: 1px solid #004581;
  border-radius: 15px 0 0 15px; }
  @media screen and (max-width: 680px) {
    #search-wrapper {
      width: 250px;
      height: 40px;
      border-radius: 0; } }
  #search-wrapper input {
    border: none;
    border-radius: 15px 0 0 15px;
    padding: 15px;
    height: 100%;
    display: block;
    width: 100%;
    box-sizing: border-box;
    transition: all .5s;
    font-size: 16px;
    font-family: "Comfortaa";
    color: #048ABD; }
    @media screen and (max-width: 680px) {
      #search-wrapper input {
        border-radius: 0; } }
  #search-wrapper #search-options-menu {
    width: 100%;
    margin: 10px auto;
    background: white;
    border: 1px solid #004581;
    border-radius: 15px;
    box-shadow: 0 0 2px 0 #011B48; }
    #search-wrapper #search-options-menu.hidden {
      display: none; }
    #search-wrapper #search-options-menu span {
      height: 40px;
      width: 100%;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      color: #004581; }
      #search-wrapper #search-options-menu span:hover {
        text-decoration: underline; }
  #search-wrapper img {
    position: absolute;
    width: 25px;
    top: 0;
    right: 5px;
    cursor: pointer; }
    @media screen and (max-width: 680px) {
      #search-wrapper img {
        width: 35px; } }

#search-button {
  height: 32px;
  width: 45px;
  line-height: 32px;
  cursor: pointer;
  background: linear-gradient(90deg, #004581, #048ABD);
  font-family: "Comfortaa";
  border-width: 0;
  border-radius: 0 15px 15px 0;
  font-size: 14px; }
  @media screen and (max-width: 680px) {
    #search-button {
      width: 252px;
      height: 40px;
      border-radius: 0 0 15px 15px;
      line-height: 40px; } }
  #search-button img {
    width: 25px;
    display: inline-block; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

.general-button {
  margin: 0 auto;
  height: 50px;
  width: 300px;
  text-align: center;
  line-height: 50px;
  vertical-align: middle;
  border-radius: 10px;
  transition: all .5s;
  cursor: pointer;
  font-size: 15px; }
  .general-button:hover {
    box-shadow: 0 0 5px 0 #011B48; }

.outlined-button {
  color: #004581;
  border: 1px solid #004581; }
  .outlined-button:hover {
    color: white;
    background: #004581;
    border-width: 0px; }

.action-button {
  color: white;
  background: linear-gradient(90deg, #004581, #048ABD); }

.liked-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  margin-top: calc(50px - 18px);
  margin-left: calc(150px - 20px); }

.audio-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  margin-top: calc(50px - 18px);
  margin-left: calc(-150px + 5px); }

.big-button {
  width: 450px; }
  @media screen and (max-width: 680px) {
    .big-button {
      width: 300px; } }

.big-button-liked-icon {
  margin-left: calc(225px - 20px); }

.big-button-audio-icon {
  margin-left: calc(-150px - 68px); }

.unavailable-button {
  background: gray;
  color: white;
  cursor: default; }
  .unavailable-button:hover {
    box-shadow: 0 0 0 0; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#request-poem-container {
  width: 70%;
  margin: 50px auto;
  text-align: center; }
  @media screen and (max-width: 1150px) {
    #request-poem-container {
      width: 80%; } }
  @media screen and (max-width: 680px) {
    #request-poem-container {
      width: 90%; } }
  #request-poem-container p {
    width: 60%;
    font-size: 20px;
    color: #004581;
    margin: 40px auto; }
    @media screen and (max-width: 1150px) {
      #request-poem-container p {
        width: 80%; } }
    @media screen and (max-width: 680px) {
      #request-poem-container p {
        width: 90%; } }
  #request-poem-container hr {
    border: #004581 0.5px solid; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#loader-container {
  width: 50px;
  margin: auto;
  text-align: center; }

#ml-loader {
  width: 50px;
  height: 50px;
  z-index: -1; }

#ml-loader div {
  -webkit-animation: 1.2s opaque ease-in-out infinite both;
  animation: 1.2s opaque ease-in-out infinite both; }

#ml-loader div::after {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 18px;
  border-radius: 10px;
  background-color: #048ABD; }

#ml-loader div:nth-child(1) {
  -webkit-transform: rotate(0);
  transform: rotate(0); }

#ml-loader div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }

#ml-loader div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

#ml-loader div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

#ml-loader div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

#ml-loader div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s; }

#ml-loader div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

#ml-loader div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s; }

#ml-loader div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

#ml-loader div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s; }

#ml-loader div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

#ml-loader div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s; }

#ml-loader div:nth-child(13) {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

@-webkit-keyframes opaque {
  0%, 40%, 100% {
    opacity: 0.1; }
  40% {
    opacity: 1; } }

@keyframes opaque {
  0%, 40%, 100% {
    opacity: 0.1; }
  40% {
    opacity: 1; } }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#poems-table-container table {
  text-align: center;
  margin: 50px auto 0; }
  @media screen and (min-width: 1604) {
    #poems-table-container table {
      min-width: calc(300px * 5); } }
  @media screen and (min-width: 1284) and (max-width: 1604) {
    #poems-table-container table {
      min-width: calc(300px * 4); } }
  @media screen and (min-width: 964) and (max-width: 1284) {
    #poems-table-container table {
      min-width: calc(300px * 3); } }
  @media screen and (min-width: 644) and (max-width: 964) {
    #poems-table-container table {
      min-width: calc(300px * 2); } }
  @media screen and (max-width: 644) {
    #poems-table-container table {
      min-width: 300px; } }

#poems-table-container tr {
  text-align: center;
  display: inline-block;
  width: 300px;
  height: 70px;
  padding: 10px; }

#poems-table-container .link {
  text-decoration: none; }

#poems-table-container .author-cell {
  text-align: left;
  font-size: 22px; }
  #poems-table-container .author-cell a {
    color: #004581; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#button-container {
  cursor: pointer;
  transition: all .2s; }
  #button-container:hover {
    box-shadow: 0 2px 4px 0 #048ABD; }

.outlined-player-button {
  border: 1px solid #004581; }

.filled-player-button {
  background: linear-gradient(90deg, #004581, #048ABD); }

.big-player-button {
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 35px; }
  .big-player-button img {
    width: 50px;
    padding: 0 10px;
    vertical-align: middle; }

.ordinary-button {
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 25px; }
  .ordinary-button img {
    width: 30px;
    padding: 0 10px;
    vertical-align: middle; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#timeline-container {
  text-align: center;
  height: 15px;
  line-height: 15px;
  margin: 20px auto; }
  #timeline-container * {
    display: inline-block;
    vertical-align: middle; }
  #timeline-container span {
    width: 40px;
    color: #004581; }

#playback-indicator {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: linear-gradient(90deg, #004581, #048ABD);
  position: absolute;
  margin: 0 13px;
  cursor: pointer; }

#progress-bar {
  height: 2px;
  margin: 0 20px;
  background: #97CBDC;
  width: 40%;
  cursor: pointer; }
  @media screen and (max-width: 1150px) {
    #progress-bar {
      width: 50%; } }
  @media screen and (max-width: 680px) {
    #progress-bar {
      width: 60%; } }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#player-buttons-container {
  text-align: center; }
  #player-buttons-container div {
    margin: 10px;
    display: inline-block; }
    @media screen and (max-width: 680px) {
      #player-buttons-container div {
        margin: 0 5px 20px; } }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#lyrics-container {
  text-align: center;
  width: 30%;
  margin: 50px auto; }
  @media screen and (max-width: 680px) {
    #lyrics-container {
      width: 100%; } }
  #lyrics-container #text-pre {
    font-family: "Comfortaa";
    line-height: 24px;
    white-space: pre-wrap; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#edit-poem-form-container {
  top: 200px;
  position: absolute;
  width: 100%; }
  #edit-poem-form-container img {
    height: 30px;
    position: relative;
    margin: 10px;
    float: right;
    cursor: pointer; }
  #edit-poem-form-container #edit-poem-form-subcontainer {
    background: white;
    border-radius: 20px;
    margin: auto;
    border: solid #048ABD 1px;
    width: 55%; }
    @media screen and (max-width: 1150px) {
      #edit-poem-form-container #edit-poem-form-subcontainer {
        width: 70%; } }
    @media screen and (max-width: 680px) {
      #edit-poem-form-container #edit-poem-form-subcontainer {
        width: 85%; } }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#poem-title-container {
  margin: 40px;
  text-align: center; }

#author-poem-title-container {
  line-height: 25px; }
  #author-poem-title-container a {
    color: #004581;
    font-size: 25px; }
  #author-poem-title-container * {
    display: inline;
    vertical-align: middle; }
  #author-poem-title-container img {
    height: 20px;
    margin: 5px;
    cursor: pointer; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#vote-for-poem-container {
  width: 70%;
  margin: 50px auto;
  text-align: center; }
  @media screen and (max-width: 1150px) {
    #vote-for-poem-container {
      width: 80%; } }
  @media screen and (max-width: 680px) {
    #vote-for-poem-container {
      width: 90%; } }
  #vote-for-poem-container p {
    width: 80%;
    font-size: 20px;
    color: #004581;
    margin: 40px auto; }
    @media screen and (max-width: 1150px) {
      #vote-for-poem-container p {
        width: 80%; } }
    @media screen and (max-width: 680px) {
      #vote-for-poem-container p {
        width: 90%; } }
  #vote-for-poem-container hr {
    border: #004581 0.5px solid; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

.poem-page-container {
  text-align: center; }
  .poem-page-container div {
    margin: auto; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#author-container {
  width: 70%;
  margin: auto;
  line-height: 24px; }
  #author-container #author-info #author-img-div {
    width: 20%; }
    @media screen and (max-width: 1150px) {
      #author-container #author-info #author-img-div {
        width: 40%;
        margin: 0 30%; } }
    @media screen and (max-width: 680px) {
      #author-container #author-info #author-img-div {
        width: 50%;
        margin: 0 25%; } }
    #author-container #author-info #author-img-div img {
      width: 100%;
      border-radius: 50%; }
  #author-container #author-info #author-header-div {
    width: 70%; }
    @media screen and (max-width: 1150px) {
      #author-container #author-info #author-header-div {
        width: 100%; } }
  #author-container #author-info div {
    vertical-align: top;
    margin: 20px 2%;
    display: inline-block; }
  #author-container #author-poems {
    text-align: center; }
    #author-container #author-poems a {
      text-decoration: none;
      margin: 10px;
      display: inline-block; }
      @media screen and (max-width: 680px) {
        #author-container #author-poems a {
          margin: 10px 0; } }
  #author-container #author-bio {
    font-family: "Comfortaa";
    white-space: pre-wrap; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#login-form-container {
  text-align: center; }
  #login-form-container a {
    text-decoration: none;
    color: #048ABD; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#profile-container {
  text-align: center;
  padding: 50px; }
  #profile-container p {
    font-size: 24px; }
  #profile-container a {
    text-decoration: none; }

#profile-liked-container {
  padding: 30px 0; }

#profile-liked-poems-container {
  width: 100%; }
  #profile-liked-poems-container a {
    margin: 10px;
    display: inline-block; }
    @media screen and (max-width: 680px) {
      #profile-liked-poems-container a {
        margin: 10px 0; } }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#profile-settings-container {
  padding: 50px; }
  #profile-settings-container a {
    text-decoration: none; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

.faqItemWrapper {
  width: 80%;
  margin: 50px auto;
  border-bottom: 1px #011B48 dashed;
  color: #004581; }

.faqQuestion {
  color: #011B48;
  font-size: 24px; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-stats-container #visits-chart {
  width: 70vw;
  height: 70vh;
  margin: auto; }

#manager-stats-container table {
  width: 90%;
  margin: 50px auto 0;
  text-align: center; }

#manager-stats-container tr {
  display: inline-block;
  cursor: default;
  border: 1px solid #004581;
  width: 35%;
  margin: 10px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  color: #004581;
  border-radius: 10px; }
  @media screen and (max-width: 1150px) {
    #manager-stats-container tr {
      width: 60%;
      margin: 5px; } }
  @media screen and (max-width: 680px) {
    #manager-stats-container tr {
      width: 90%; } }

#manager-stats-container td {
  width: 1%;
  margin: auto; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-actions-container table {
  width: 90%;
  margin: 50px auto 0;
  text-align: center; }

#manager-actions-container tr {
  display: inline-block;
  margin: 10px; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#not-found-container {
  text-align: center;
  margin: 50px; }
  #not-found-container span {
    font-size: 72px; }
  #not-found-container p {
    font-size: 36px; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-page-container a {
  text-decoration: none; }



body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#tableview-container table {
  width: 80%;
  margin: 30px auto;
  text-align: center;
  color: #004581;
  border-collapse: collapse; }
  #tableview-container table th {
    color: #011B48;
    height: 40px; }
  #tableview-container table tr:hover {
    background: #DDE8F0; }
  #tableview-container table #header-row:hover {
    background: transparent; }
  #tableview-container table .action-tag {
    cursor: pointer; }
  #tableview-container table img {
    height: 30px; }
  #tableview-container table a {
    text-decoration: none;
    color: #004581; }
  #tableview-container table td {
    height: 30px;
    border: 1px solid #97CBDC; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-proposal-poems-container p {
  text-align: center;
  margin: 50px auto;
  font-size: 26px; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-feedback-container p {
  text-align: center;
  margin: 50px auto;
  font-size: 26px; }

#manager-feedback-container td {
  padding: 15px 0; }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-edit-popup-container {
  height: 100vh;
  width: 100vw;
  top: 10vh;
  position: fixed; }
  #manager-edit-popup-container img {
    height: 30px;
    position: relative;
    margin: 10px;
    float: right;
    cursor: pointer; }
  #manager-edit-popup-container #manager-edit-popup-subcontainer {
    background: white;
    border-radius: 20px;
    margin: auto;
    border: solid #048ABD 1px;
    width: 55%; }
    @media screen and (max-width: 1150px) {
      #manager-edit-popup-container #manager-edit-popup-subcontainer {
        width: 70%; } }
    @media screen and (max-width: 680px) {
      #manager-edit-popup-container #manager-edit-popup-subcontainer {
        width: 85%; } }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-database-container {
  text-align: center; }
  #manager-database-container p {
    margin: 50px auto;
    font-size: 26px; }
  #manager-database-container select {
    font-size: 18px; }
  #manager-database-container a {
    cursor: pointer; }
    #manager-database-container a:hover {
      text-decoration: underline; }
  #manager-database-container button {
    cursor: pointer;
    font-family: "Comfortaa";
    color: #004581;
    font-size: 16px;
    background: none;
    border: none; }
    #manager-database-container button:hover {
      text-decoration: underline; }
  @media screen and (max-width: 1150px) {
    #manager-database-container table {
      font-size: 14px; } }
  @media screen and (max-width: 680px) {
    #manager-database-container table {
      font-size: 12px; } }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-analytics-container {
  text-align: center; }
  #manager-analytics-container .logged-in {
    background-color: #DDE8F0; }
  #manager-analytics-container p {
    margin: 50px auto;
    font-size: 26px; }
  #manager-analytics-container select {
    font-size: 18px; }
  #manager-analytics-container a {
    cursor: pointer; }
    #manager-analytics-container a:hover {
      text-decoration: underline; }
  @media screen and (max-width: 1150px) {
    #manager-analytics-container table {
      font-size: 14px; } }
  @media screen and (max-width: 680px) {
    #manager-analytics-container table {
      font-size: 12px; } }

body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

