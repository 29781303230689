@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

.poem-page-container {
  text-align: center; }
  .poem-page-container div {
    margin: auto; }
