@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#hamburger-button {
  vertical-align: middle;
  width: 40px;
  height: 30px;
  margin-right: 20px;
  transform: rotate(0deg);
  cursor: pointer;
  display: inline-block; }

#hamburger-button span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #004581;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

#hamburger-button span:nth-child(1) {
  top: 0px; }

#hamburger-button span:nth-child(2), #hamburger-button span:nth-child(3) {
  top: 9px; }

#hamburger-button span:nth-child(4) {
  top: 18px; }

#hamburger-button.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%; }

#hamburger-button.open span:nth-child(2) {
  transform: rotate(45deg); }

#hamburger-button.open span:nth-child(3) {
  transform: rotate(-45deg); }

#hamburger-button.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%; }
