@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#button-container {
  cursor: pointer;
  transition: all .2s; }
  #button-container:hover {
    box-shadow: 0 2px 4px 0 #048ABD; }

.outlined-player-button {
  border: 1px solid #004581; }

.filled-player-button {
  background: linear-gradient(90deg, #004581, #048ABD); }

.big-player-button {
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 35px; }
  .big-player-button img {
    width: 50px;
    padding: 0 10px;
    vertical-align: middle; }

.ordinary-button {
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 25px; }
  .ordinary-button img {
    width: 30px;
    padding: 0 10px;
    vertical-align: middle; }
