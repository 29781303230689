@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#edit-poem-form-container {
  top: 200px;
  position: absolute;
  width: 100%; }
  #edit-poem-form-container img {
    height: 30px;
    position: relative;
    margin: 10px;
    float: right;
    cursor: pointer; }
  #edit-poem-form-container #edit-poem-form-subcontainer {
    background: white;
    border-radius: 20px;
    margin: auto;
    border: solid #048ABD 1px;
    width: 55%; }
    @media screen and (max-width: 1150px) {
      #edit-poem-form-container #edit-poem-form-subcontainer {
        width: 70%; } }
    @media screen and (max-width: 680px) {
      #edit-poem-form-container #edit-poem-form-subcontainer {
        width: 85%; } }
