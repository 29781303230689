@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

footer {
  margin: 2%;
  text-align: center;
  font-size: 14px; }
  footer .pl-title {
    font-size: 20px; }
  footer a, footer span {
    color: #011B48;
    line-height: 20px; }
