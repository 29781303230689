@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#tableview-container table {
  width: 80%;
  margin: 30px auto;
  text-align: center;
  color: #004581;
  border-collapse: collapse; }
  #tableview-container table th {
    color: #011B48;
    height: 40px; }
  #tableview-container table tr:hover {
    background: #DDE8F0; }
  #tableview-container table #header-row:hover {
    background: transparent; }
  #tableview-container table .action-tag {
    cursor: pointer; }
  #tableview-container table img {
    height: 30px; }
  #tableview-container table a {
    text-decoration: none;
    color: #004581; }
  #tableview-container table td {
    height: 30px;
    border: 1px solid #97CBDC; }
