@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#info-alert-container {
  width: 60%;
  padding: 20px;
  margin: 20px auto;
  border-radius: 10px;
  text-align: center;
  transition: all 1s; }

.error-style {
  border: 1px #b7241b solid;
  background-color: #fcd1d2;
  color: #b7241b; }

.success-style {
  border: 1px #157711 solid;
  background-color: #d9efce;
  color: #157711; }

.info-style {
  border: 1px #004581 solid;
  background-color: #DDE8F0;
  color: #004581; }
