@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-database-container {
  text-align: center; }
  #manager-database-container p {
    margin: 50px auto;
    font-size: 26px; }
  #manager-database-container select {
    font-size: 18px; }
  #manager-database-container a {
    cursor: pointer; }
    #manager-database-container a:hover {
      text-decoration: underline; }
  #manager-database-container button {
    cursor: pointer;
    font-family: "Comfortaa";
    color: #004581;
    font-size: 16px;
    background: none;
    border: none; }
    #manager-database-container button:hover {
      text-decoration: underline; }
  @media screen and (max-width: 1150px) {
    #manager-database-container table {
      font-size: 14px; } }
  @media screen and (max-width: 680px) {
    #manager-database-container table {
      font-size: 12px; } }
