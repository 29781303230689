@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#mobile-header-menu-container .mobile-header-search {
  display: block;
  text-align: center; }
  #mobile-header-menu-container .mobile-header-search:focus {
    width: 200px; }

#mobile-header-menu-container .mobile-header-menu {
  border-radius: 40px;
  display: block;
  height: 0;
  right: 0;
  position: absolute;
  background: #004581;
  transition: all .4s;
  top: 70px;
  z-index: 1000; }
  #mobile-header-menu-container .mobile-header-menu a, #mobile-header-menu-container .mobile-header-menu span {
    color: white;
    display: block;
    height: 0;
    font-size: 0;
    transition: .3s;
    text-align: center; }
  #mobile-header-menu-container .mobile-header-menu input {
    margin: auto;
    height: 0;
    transition: .3s; }
  @media screen and (min-width: 1150px) {
    #mobile-header-menu-container .mobile-header-menu {
      margin-right: 20px;
      width: 300px; }
      #mobile-header-menu-container .mobile-header-menu a, #mobile-header-menu-container .mobile-header-menu span {
        width: 238px; } }
  @media screen and (min-width: 680px) and (max-width: 1150px) {
    #mobile-header-menu-container .mobile-header-menu {
      margin-right: 20px;
      width: 300px; }
      #mobile-header-menu-container .mobile-header-menu a, #mobile-header-menu-container .mobile-header-menu span {
        width: 238px; } }
  @media screen and (max-width: 680px) {
    #mobile-header-menu-container .mobile-header-menu {
      margin: 0 10%;
      width: 80%; }
      #mobile-header-menu-container .mobile-header-menu #auth-nav-block-container, #mobile-header-menu-container .mobile-header-menu #site-nav-block-container {
        width: 100%;
        text-align: center; } }

#mobile-header-menu-container .mobile-opened-header-menu {
  height: 340px; }
  #mobile-header-menu-container .mobile-opened-header-menu a, #mobile-header-menu-container .mobile-opened-header-menu span {
    height: 70px;
    font-size: 16px;
    transition: .5s; }
  #mobile-header-menu-container .mobile-opened-header-menu input {
    height: 30px;
    transition: .5s; }

#mobile-header-menu-container .mobile-opened-header-menu.is-admin {
  height: 430px; }
