@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#manager-edit-popup-container {
  height: 100vh;
  width: 100vw;
  top: 10vh;
  position: fixed; }
  #manager-edit-popup-container img {
    height: 30px;
    position: relative;
    margin: 10px;
    float: right;
    cursor: pointer; }
  #manager-edit-popup-container #manager-edit-popup-subcontainer {
    background: white;
    border-radius: 20px;
    margin: auto;
    border: solid #048ABD 1px;
    width: 55%; }
    @media screen and (max-width: 1150px) {
      #manager-edit-popup-container #manager-edit-popup-subcontainer {
        width: 70%; } }
    @media screen and (max-width: 680px) {
      #manager-edit-popup-container #manager-edit-popup-subcontainer {
        width: 85%; } }
