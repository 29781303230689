@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#timeline-container {
  text-align: center;
  height: 15px;
  line-height: 15px;
  margin: 20px auto; }
  #timeline-container * {
    display: inline-block;
    vertical-align: middle; }
  #timeline-container span {
    width: 40px;
    color: #004581; }

#playback-indicator {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: linear-gradient(90deg, #004581, #048ABD);
  position: absolute;
  margin: 0 13px;
  cursor: pointer; }

#progress-bar {
  height: 2px;
  margin: 0 20px;
  background: #97CBDC;
  width: 40%;
  cursor: pointer; }
  @media screen and (max-width: 1150px) {
    #progress-bar {
      width: 50%; } }
  @media screen and (max-width: 680px) {
    #progress-bar {
      width: 60%; } }
