@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#poems-table-container table {
  text-align: center;
  margin: 50px auto 0; }
  @media screen and (min-width: 1604) {
    #poems-table-container table {
      min-width: calc(300px * 5); } }
  @media screen and (min-width: 1284) and (max-width: 1604) {
    #poems-table-container table {
      min-width: calc(300px * 4); } }
  @media screen and (min-width: 964) and (max-width: 1284) {
    #poems-table-container table {
      min-width: calc(300px * 3); } }
  @media screen and (min-width: 644) and (max-width: 964) {
    #poems-table-container table {
      min-width: calc(300px * 2); } }
  @media screen and (max-width: 644) {
    #poems-table-container table {
      min-width: 300px; } }

#poems-table-container tr {
  text-align: center;
  display: inline-block;
  width: 300px;
  height: 70px;
  padding: 10px; }

#poems-table-container .link {
  text-decoration: none; }

#poems-table-container .author-cell {
  text-align: left;
  font-size: 22px; }
  #poems-table-container .author-cell a {
    color: #004581; }
