@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

.faqItemWrapper {
  width: 80%;
  margin: 50px auto;
  border-bottom: 1px #011B48 dashed;
  color: #004581; }

.faqQuestion {
  color: #011B48;
  font-size: 24px; }
