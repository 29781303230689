@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

header {
  padding-left: 3%;
  height: 70px;
  line-height: 70px; }
  header div {
    display: inline-block; }
  header a {
    color: white;
    cursor: pointer;
    transition: all .2s ease-in-out; }

.nav-container {
  float: right; }

.header-title {
  font-size: 40px;
  text-decoration: none;
  color: #004581; }

.nav-search {
  border-left: 1px dashed #97CBDC;
  border-right: 1px dashed #97CBDC;
  border-radius: 15px;
  border-width: 0;
  width: 150px;
  height: 30px;
  padding: 0 20px;
  font-size: 18px;
  color: #011B48;
  font-family: "Comfortaa";
  margin: 0 20px;
  transition: all .5s; }
  .nav-search:focus {
    width: 220px;
    outline-width: 0; }

@media screen and (min-width: 680px) and (max-width: 1150px) {
  .header-search {
    border: 0; } }

@media screen and (max-width: 680px) {
  .header-search {
    display: none; } }

.nav-item {
  padding: 0 30px;
  text-decoration: none; }
  @media screen and (max-width: 1150px) {
    .nav-item {
      display: none; } }
