@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

.general-button {
  margin: 0 auto;
  height: 50px;
  width: 300px;
  text-align: center;
  line-height: 50px;
  vertical-align: middle;
  border-radius: 10px;
  transition: all .5s;
  cursor: pointer;
  font-size: 15px; }
  .general-button:hover {
    box-shadow: 0 0 5px 0 #011B48; }

.outlined-button {
  color: #004581;
  border: 1px solid #004581; }
  .outlined-button:hover {
    color: white;
    background: #004581;
    border-width: 0px; }

.action-button {
  color: white;
  background: linear-gradient(90deg, #004581, #048ABD); }

.liked-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  margin-top: calc(50px - 18px);
  margin-left: calc(150px - 20px); }

.audio-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  margin-top: calc(50px - 18px);
  margin-left: calc(-150px + 5px); }

.big-button {
  width: 450px; }
  @media screen and (max-width: 680px) {
    .big-button {
      width: 300px; } }

.big-button-liked-icon {
  margin-left: calc(225px - 20px); }

.big-button-audio-icon {
  margin-left: calc(-150px - 68px); }

.unavailable-button {
  background: gray;
  color: white;
  cursor: default; }
  .unavailable-button:hover {
    box-shadow: 0 0 0 0; }
