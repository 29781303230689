@import url("https://fonts.googleapis.com/css?family=Yellowtail");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
body {
  font-family: "Comfortaa"; }

.pl-title {
  font-family: "Yellowtail"; }
  .pl-title:before {
    content: "Poem Library"; }

#form-view-container {
  text-align: center;
  margin: 50px auto; }
  #form-view-container textarea, #form-view-container input {
    display: block;
    font-family: "Comfortaa";
    margin: 10px auto;
    color: #011B48;
    text-align: center; }
  #form-view-container .form-free-text {
    font-size: 18px;
    margin: 10px auto; }
  #form-view-container .form-input {
    width: 450px;
    font-size: 16px;
    border-radius: 5px;
    border: 0.5px solid #004581;
    background-color: white; }
    @media screen and (max-width: 680px) {
      #form-view-container .form-input {
        width: 80%; } }
  #form-view-container .form-textfield {
    height: 40px; }
  #form-view-container textarea {
    height: 200px;
    resize: none; }
  #form-view-container .form-button {
    color: white;
    width: 350px;
    border: 0;
    margin: 30px auto; }
    @media screen and (max-width: 680px) {
      #form-view-container .form-button {
        width: 85%; } }
  #form-view-container .file-input {
    background-color: #004581;
    cursor: pointer;
    width: 450px;
    height: 45px;
    line-height: 45px;
    vertical-align: middle;
    color: white;
    text-align: center;
    margin: auto;
    border-radius: 5px;
    transition: all .1s; }
    @media screen and (max-width: 680px) {
      #form-view-container .file-input {
        width: 85%; } }
    #form-view-container .file-input:hover {
      background-color: #048ABD; }
    #form-view-container .file-input label {
      cursor: pointer; }
    #form-view-container .file-input input {
      visibility: hidden; }
